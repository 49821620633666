import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const AssessmentResultStyles = styled.div`
  background-color: ${theme.color.bg.lighter};
  color: ${theme.color.text.dark};
  font-family: ${theme.font.serif.light};

  .wrapper {
    margin-right: auto;
    margin-left: auto;
  }

  > .wrapper {
    padding: 0 1rem;

    @media ${device.medium} {
      padding: 0 1vw;
    }
  }

  .hero {
    height: 95vh;
    position: relative;
    width: 100vw;

    @media ${device.medium} {
      height: 50vw;
    }
  }

  .hero-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .hero-banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    > div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &.hero-banner-2 {
        background-position: center top -5vh;
        background-size: auto 125vh;

        @media ${device.medium} {
          background-position: center;
          background-size: cover;
        }
      }

      &.hero-banner-4 {
        background-position: right -30vh bottom;
        background-size: auto 82vh;

        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          height: 2px;
          background: ${theme.color.neutral.lighter};
        }

        @media ${device.small} {
          background-position: right -20vh bottom;
          background-size: auto 82vh;
        }

        @media ${device.medium} {
          background-position: center;
          background-size: cover;
        }
      }
    }
  }

  .site-nav {
    position: relative;
    padding-top: 4vw;
    z-index: 100;
  }

  .logo {
    width: 80px;

    @media ${device.medium} {
      width: 12vw;
    }
  }

  .section-heading {
    color: ${theme.color.text.dark};
    font-family: ${theme.font.serif.light};
    font-size: 4vw;
    font-weight: normal;
    margin-left: 1rem;

    @media ${device.medium} {
      margin-left: 2rem;
    }
  }

  .section-subheading {
    color: ${theme.color.tan.default};
    font-size: 2.2vw;
    margin-bottom: 2rem;
    margin-left: 2rem;

    @media ${device.medium} {
      max-width: 60vw;
    }
  }

  .hero-copy {
    padding: 45vh 0 0 1rem;

    @media ${device.medium} {
      padding: 20vw 0 0 4vw;
    }

    h1 {
      color: ${theme.color.secondary.default};
      font-family: ${theme.font.serif.regular};
      font-size: 16px;
      font-weight: normal;
      line-height: 1.2;
      margin: 0;

      @media ${device.small} {
        font-size: 18px;
      }

      @media ${device.medium} {
        font-size: 4vw;
      }

      strong {
        color: ${theme.color.text.light};
        font-family: ${theme.font.serif.light};
        font-size: 32px;
        font-weight: normal;

        @media ${device.small} {
          font-size: 38px;
        }

        @media ${device.medium} {
          font-size: 7vw;
        }
      }
    }
  }

  .overview-wrapper {
    @media ${device.medium} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  .overview-header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 2rem;

    h3 {
      font-size: 2.6vw;
      font-weight: normal;
      margin-top: 0;
    }
  }

  .overview-sleep {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${device.medium} {
      margin-right: ${theme.padding.small};
      width: calc(50% - ${theme.padding.small});
    }

    .overview-header {
      background-color: ${theme.color.primary.darker};
      background-image: url('/images/bg-header-nighttime.png');
      color: ${theme.color.text.light};
    }
  }

  .overview-daytime {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${device.medium} {

      width: calc(50% - ${theme.padding.small});
    }

    .overview-header {
      background-color: ${theme.color.neutral.default};
      background-image: url('/images/bg-header-daytime.png');
      color: ${theme.color.text.dark};
    }

    .sliderGraphEl {
      left: calc(98% - 30px);
    }
  }

  .overview-content {
    background-color: ${theme.color.bg.light};
    padding: ${theme.padding.base} ${theme.padding.base};
    flex-grow: 1;

    p:first-of-type {
      margin-top: 0;
    }
  }

  .sleep-factors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: ${theme.padding.large};

    @media ${device.medium} {
      flex-wrap: nowrap;
    }
  }

  .sleep-factor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 2rem);
    margin: 0 1rem 2rem;

    @media ${device.medium} {
      margin: 0 0.5rem;
      width: 25%;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &[data-factor='big'] {
      h3,
      h4 {
        color: ${theme.color.utility.error};
      }
    }

    &[data-factor='good'] {
      h3,
      h4 {
        color: ${theme.color.utility.info};
      }
    }

    &[data-factor='small'] {
      h3,
      h4 {
        color: ${theme.color.utility.warning};
      }
    }
  }

  .sleep-factor-header {
    display: flex;
    flex-direction: row;
    height: 14vw;
  }

  .sleep-factor-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 4px;

    img {
      width: 100%;
    }
  }

  .factors-grid-item-header {
    color: ${theme.color.neutral.lighter};
    font-family: ${theme.font.serif.bold};
    display: block;
    width: 100%;
    height: 0;
    padding: 1rem 1rem calc(62.5% - 0.5rem);
    position: relative;
    text-decoration: none;

    @media ${device.medium} {
      padding: 0.5rem 0.5rem calc(62.5% - 0.5rem);
    }

    &:hover {
      opacity: 0.85;
    }

    svg {
      width: 60%;

      @media ${device.medium} {
        height: 6vw;
        width: auto;
      }
    }

    .factor-icon {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      width: 40%;

      @media ${device.medium} {
        height: calc(100% - 2rem);
        width: auto;
      }
    }

    ul {
      position: absolute;
      bottom: 1em;
      left: 1em;
      margin: 0;
      padding: 0;
      list-style: none;

      @media ${device.medium} {
        bottom: 0.5em;
        left: 0.5em;
        font-size: 1.3vw;
        max-width: 10vw;
      }

      li {
        line-height: 1;
        margin-bottom: 0.65em;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .factor-text {
    font-size: 6vw;

    @media ${device.medium} {
      font-size: 2vw;
      padding: 0 2rem;
    }

    @media ${device.large} {
      font-size: 2.2vw;
    }
  }

  .sleep-factor-graph {
    width: 80%;
    margin: 0 auto;

    @media ${device.medium} {
      width: initial;
      margin: initial;
    }
  }

  [data-factor-type='mind'] .factors-grid-item-header {
    background-color: ${theme.color.factor.mind};
  }

  [data-factor-type='body'] .factors-grid-item-header {
    background-color: ${theme.color.factor.body};
  }

  [data-factor-type='social'] .factors-grid-item-header {
    background-color: ${theme.color.factor.social};
  }

  [data-factor-type='routine'] .factors-grid-item-header {
    background-color: ${theme.color.factor.routine};
  }

  [data-factor-type='environment'] .factors-grid-item-header {
    background-color: ${theme.color.factor.environment};
  }

  .sleep-factor-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: ${theme.padding.small};
    width: 50%;
    padding: ${theme.padding.small} ${theme.padding.small} ${theme.padding.small} 0;

    img {
      height: 4vw;
      margin-left: -10px;
    }
  }

  .sleep-factor-content {
    background-color: ${theme.color.neutral.light};
    flex-grow: 2;
    padding: ${theme.padding.small};

    h4 {
      margin-top: 0;
    }

    img {
      margin-bottom: 2rem;
    }

    p {
      font-size: 14px;
      line-height: 1.4;
      min-height: 100px;
    }
  }

  .sleep-factor-social {
    .sleep-factor-icon {
      width: 45%;
    }

    .sleep-factor-title {
      margin-left: 0;
      width: 55%;
    }
  }

  .product-grid {
    padding: 1rem;
    margin-bottom: 4rem;

    @media ${device.medium} {
      display: flex;
      flex-direction: row;
      padding: 0;
    }
  }

  .product {
    margin-bottom: 2rem;

    @media ${device.medium} {
      display: flex;
      padding:  0.5rem;
      width: 33.33%;

      &:first-of-type {
        padding-left: 1rem;
      }

      &:last-of-type {
        padding-right: 1rem;
      }
    }

    @media ${device.large} {
      padding: ${theme.padding.small};
    }
  }

  .product-details {
    background-color: ${theme.color.secondary.lighter};
    font-size: 14px;
    padding: 1rem 2rem;
  }

  .product-title {
    color: ${theme.color.text.dark};
    font-family: ${theme.font.serif.regular};
    font-size: 3vw;
    font-weight: normal;
    margin: 0;
  }

  .product-price {
    color: ${theme.color.secondary.dark};
    font-family: ${theme.font.serif.medium};
    font-size: 1.8vw;
    margin-bottom: 1rem;
  }

  .product-description {
    margin-bottom: 1rem;
  }

  .product-button {
    background-color: ${theme.color.forest.default};
    border: 0;
    border-radius: 0.75vw;
    color: ${theme.color.text.light};
    cursor: pointer;
    font-family: ${theme.font.serif.medium};
    font-size: 1.6vw;
    padding: 0.8vw 2vw;

    &:hover {
      background-color: ${theme.color.forest.light};
    }
  }

  .product-factor-grid {
    display: flex;
    flex-direction: row;
  }

  .product-factor {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 4px ${theme.padding.base};
    padding: 10px 4px;
    position: relative;
    width: 25%;

    &[factor-active='true'] {
      background-color: ${theme.color.bg.lighter};
      svg path {
        fill: ${theme.color.forest.default};
      }
    }

    &[factor-active='false'] {
      background-color: ${theme.color.secondary.light};
      &:after {
        color: ${theme.color.secondary.default};
      }
      svg path {
        fill: ${theme.color.secondary.default};
      }
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.factor-body {
      &:after {
        content: 'Body';
      }
    }

    &.factor-social {
      &:after {
        content: 'Social';
      }
    }

    &.factor-mind {
      &:after {
        content: 'Mind';
      }
    }

    &.factor-routine {
      &:after {
        content: 'Routine';
      }
    }

    &:after {
      bottom: -1.4rem;
      color: ${theme.color.forest.default};
      left: 0;
      right: 0;
      position: absolute;
      text-align: center;
    }

    svg {
      width: 95%;
    }
  }

  .cta-create-account {
    background-image: url('../images/bg-cta-create-account.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 2rem 1rem 2rem;

    @media ${device.medium} {
      height: 50vw;
      padding: initial;
    }

    .section-heading {
      color: ${theme.color.text.light};
      font-size: 6.8vw;
    }

    button,
    .button {
      background-color: ${theme.color.secondary.default};
      border: 0;
      border-radius: 0.5rem;
      color: ${theme.color.text.light};
      cursor: pointer;
      font-family: ${theme.font.serif.medium};
      font-size: 16px;
      padding: ${theme.padding.small} ${theme.padding.base};
      text-decoration: none;

      @media ${device.medium} {
        font-size: 2.1vw;
      }

      &:hover {
        background-color: ${theme.color.secondary.light};
      }
    }
  }

  .add-to-cart {
    float: right;
  }

  .assessment-container {
    min-height: 195px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .assessment-title {
    padding: 20px;
    background-color: ${theme.color.neutral.default};
  }

  .assessment-content {
    padding: 20px;
    background-color: #f2efec;
    font-size: 12px;
  }
`;

export default AssessmentResultStyles;
