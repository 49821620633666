/* eslint-disable */
import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  description: undefined,
  externalId: undefined,
  factor: undefined,
  featuredImageUrl: undefined,
  price: undefined,
  title: undefined,
  percentMatch: undefined,
  matchScore: undefined,
  handle: undefined,
};

class ProductModel extends Record(DEFAULT_ARGS) {
  static _name = 'Somn::Product';
  // productAddUrl: undefined,
  // productUrl: undefined,
}

export default ProductModel;
