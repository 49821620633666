import styled from 'styled-components';
import theme from '../../../../common/theme';
import device from '../../../../common/device';
import FactorGraphStyles from '../factors/factor_graph/factor_graph_styles';

const FullReportStyles = styled.div`
  background-color: ${theme.color.white};

  .content {
    margin: 0 auto;
    max-width: 68rem;
    width: 100%;
  }

  .wrapper-intro {
    background-image: url('/images/bg-full-results-header.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: auto
    justify-content: center;
    width: 100%;

    @media ${device.medium} {
      height: 24.5vw;
    }
  }

  .wrapper-overview {
    .overview-header {
      margin-bottom: 1rem;
    }

    .overview-grid {
      @media ${device.medium} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .overview {
        margin-bottom: 2rem;

        @media ${device.medium} {
          margin: 0 1rem 0;
          width: calc(50% - 1rem);

          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }

        @media ${device.medium} {
          margin: 0 2rem;
        }
      }
    }
  }

  .sleep-factor {
    margin: 0 -1.5rem;

    @media ${device.medium} {
      margin: 0 0 2rem;
    }
  }

  .sleep-factor-header {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    width: 100%;

    @media ${device.medium} {
      padding: 0.25rem 0.5rem;
    }
  }

  [data-factor='mind'] .sleep-factor-header {
    background-color: ${theme.color.factor.mind};
  }

  [data-factor='body'] .sleep-factor-header {
    background-color: ${theme.color.factor.body};
  }

  [data-factor='social'] .sleep-factor-header {
    background-color: ${theme.color.factor.social};
  }

  [data-factor='routine'] .sleep-factor-header {
    background-color: ${theme.color.factor.routine};
  }

  [data-factor='environment'] .sleep-factor-header {
    background-color: ${theme.color.factor.environment};
  }

  .factor-icon {
    height: 74px;
    margin-right: 1rem;
    width: auto;
  }

  .factor-name {
    display: flex;
    flex-direction: column;

    svg {
      width: auto;
    }
  }

  .factor-header {
    height: 50px;
    width: auto;
  }

  .sleep-factor-results {
    display: flex;
    flex-direction: column;

    @media ${device.medium} {
      flex-direction: row;
    }

    a {
      display: block;
      position: relative;
    }

    .factor-text {
      font-family: ${theme.font.serif.bold};
      bottom: 0.25vw;
      line-height: 1;
      position: absolute;
      text-align: center;
      width: 100%;
      color: ${theme.color.text.dark};
      padding: 0;
      font-size: 6vw;

      @media ${device.medium} {
        font-size: 1.5vw;
        padding: 0 2rem;
      }

      @media ${device.large} {
        font-size: 1.5vw;
      }

      &.environment {
        font-size: 16px;
        padding: 0 1rem;

        @media ${device.medium} {
          font-size: 1.35vw;
        }
      }
    }

    a {
      text-decoration: none;
    }
  }

  ${FactorGraphStyles},
  .factor-graph {
    display: block;
    margin: 0 auto 0.5rem;
    width: 80%;

    @media ${device.medium} {
      margin: 0;
      width: 100%;
    }
  }

  .sleep-factor-sidebar {
    background-color: ${theme.color.neutral.default};
    padding: 1.5rem;
    width: 100%;

    @media ${device.medium} {
      padding: 2vw;
      width: 25%;
    }

    a {
      color: ${theme.color.text.dark};
    }
  }

  .sleep-factor-details {
    background-color: ${theme.color.neutral.light};
    padding: 1.5rem;
    width: 100%;

    @media ${device.medium} {
      padding: 2vw;
      width: 75%;
    }

    .learn-more {
      position: relative;
      top: 25px;
      margin-top: 75px;
      font-size: 1em;
      text-align: center;

      @media ${device.medium} {
        text-align: center;
      }

      a {
        display: inline-block;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin-top: 40px;
        width: 250px;
        height: 45px;
      }
    }
  }

  .sleep-factor-detail {
    margin-bottom: 2rem;

    @media ${device.medium} {
      margin-bottom: 2vw;
    }
  }

  .inline {
    margin-bottom: 0.25rem;

    @media ${device.medium} {
      ${'' /* display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 0.5vw; */}
    }
  }

  .spacer {
    display: none;

    &:before {
      content: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
    }
  }
`;

export default FullReportStyles;
