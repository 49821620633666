import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { getFactorCopy } from '../../copy';
import { getWordpressUrl } from '../../wp';
import ResponseModel from '../../models/response_model';

// Import UI components
import { TextHeading, TextSubheading, Text } from '../../../common/components/text';
import { FactorGraph, FactorHeader, FactorIcon } from '../reports/factors';
import Header from '../header';
import Footer from '../footer';
import Product from '../product';
import Slider from '../slider';

// Import Styles
import AssessmentResultStyles from './assessment_result_styles';
import theme from '../../../common/theme';

const propTypes = {
  response: PropTypes.instanceOf(ResponseModel),
  fetchResponse: PropTypes.func,
  fetchProducts: PropTypes.func,
  user: PropTypes.shape({}).isRequired,
  products: PropTypes.shape([]).isRequired,
  signedIn: PropTypes.bool.isRequired,
};

const defaultProps = {
  response: new ResponseModel(),
  fetchResponse: undefined,
  fetchProducts: undefined,
};
// response
const AssessmentResultView = ({ fetchResponse, fetchProducts, user, response, products, signedIn }) => {
  useEffect(() => {
    fetchResponse();
  }, [fetchResponse]);
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);
  return (
    <AssessmentResultStyles>
      <div className="hero">
        <div className="hero-banner">
          <div className="hero-banner-1" style={{ backgroundImage: 'url(../images/hero-bg-01.svg)' }} />
          <div className="hero-banner-2" style={{ backgroundImage: 'url(../images/hero-bg-02.svg)' }} />
          <div className="hero-banner-3" style={{ backgroundImage: 'url(../images/hero-bg-03.svg)' }} />
          <div className="hero-banner-4" style={{ backgroundImage: 'url(../images/hero-bg-04.svg)' }} />
        </div>

        <div className="site-nav wrapper">
          <Header />
        </div>
        <div className="hero-content">
          <div className="hero-copy wrapper">
            <h1>
              {response ? <strong>{user.signedIn ? user.firstName : response.username}’s</strong> : <strong>Loading...</strong>}
              <br />
              Somn Assessment Results
            </h1>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="section-heading">
          <TextHeading size="large" margin="both">Overview</TextHeading>
        </div>

        <div className="overview-wrapper">
          <div className="overview-sleep">
            <div className="overview-header">
              <TextHeading size="medium" color="light">Your Sleep</TextHeading>
              <Slider percent={response.score('nighttimeSleepScore')} labelLow="Great" labelMid="Ok" labelHigh="Poor" />
            </div>
            <div className="overview-content">
              <p>{getFactorCopy('nighttimeSleep', response.score('nighttimeSleepScore'))}</p>
            </div>
          </div>

          <div className="overview-daytime">
            <div className="overview-header">
              <TextHeading size="medium">Your Day</TextHeading>
              <Slider percent={response.score('daytimeFunctionScore')} labelLow="Great" labelMid="Ok" labelHigh="Poor" />
            </div>
            <div className="overview-content">
              <p>{getFactorCopy('daytimeFunction', response.score('daytimeFunctionScore'))}</p>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="section-heading">
            <TextHeading size="large" margin="top">The Sleep Factors</TextHeading>
          </div>
          <div className="section-subheading">
            <TextSubheading color={theme.color.tan.default}>
              Let&#39;s identify the root cause of your sleep struggles so you can find relevant solutions that make a meaningful difference.
            </TextSubheading>
          </div>
        </div>

        <div className="sleep-factors">
          <div className="sleep-factor sleep-factor-mind" data-factor="small" data-factor-type="mind">
            <a className="factors-grid-item-header" href={getWordpressUrl('/knowledge/resources/factor-mind')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="mind" />
                <ul>
                  <li>Anxieties</li>
                  <li>Worries</li>
                  <li>Feelings</li>
                </ul>
                <FactorIcon factor="mind" className="factor-icon" />
              </div>
            </a>
            <div className="sleep-factor-content">
              <div className="sleep-factor-graph">
                <FactorGraph percent={response.score('weightedMindScore')} id="social" />
              </div>
              <Text>
                <p>{getFactorCopy('mind', response.score('weightedMindScore'))}</p>
              </Text>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-body" data-factor="good" data-factor-type="body">
            <a className="factors-grid-item-header" href={getWordpressUrl('/knowledge/resources/factor-body')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="body" />
                <ul>
                  <li>Pain</li>
                  <li>Discomfort</li>
                  <li>Biology</li>
                </ul>
                <FactorIcon factor="body" className="factor-icon" />
              </div>
            </a>
            <div className="sleep-factor-content">
              <div className="sleep-factor-graph">
                <FactorGraph percent={response.score('weightedBodyScore')} id="social" />
              </div>
              <Text>
                <p>{getFactorCopy('body', response.score('weightedBodyScore'))}</p>
              </Text>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-social" data-factor="big" data-factor-type="social">
            <a className="factors-grid-item-header" href={getWordpressUrl('/knowledge/resources/factor-social')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="social" />
                <ul>
                  <li>External Pressures</li>
                  <li>Obligations</li>
                  <li>Relationships</li>
                </ul>
                <FactorIcon factor="social" className="factor-icon" />
              </div>
            </a>
            <div className="sleep-factor-content">
              <div className="sleep-factor-graph">
                <FactorGraph percent={response.score('weightedSocialScore')} id="social" />
              </div>
              <Text>
                <p>{getFactorCopy('social', response.score('weightedSocialScore'))}</p>
              </Text>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-routine" data-factor="small" data-factor-type="routine">
            <a className="factors-grid-item-header" href={getWordpressUrl('/knowledge/resources/factor-routine')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="routine" />
                <ul>
                  <li>Personal Habit</li>
                  <li>Behaviors</li>
                  <li>Choices</li>
                </ul>
                <FactorIcon factor="routine" className="factor-icon" />
              </div>
            </a>
            <div className="sleep-factor-content">
              <div className="sleep-factor-graph">
                <FactorGraph percent={response.score('weightedRoutineScore')} id="social" />
              </div>
              <Text>
                <p>{getFactorCopy('routine', response.score('weightedRoutineScore'))}</p>
              </Text>
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <div className="section-heading">
            <TextHeading size="large" margin="both">Recommendations for you</TextHeading>
          </div>
        </div>

        <div className="product-grid">
          {products.size > 0 &&
            products.slice(0, 3).map(({ id, title, description, percentMatch, price, featuredImageUrl, factor, handle }) => (
              <div className="product" key={id}>
                <Product
                  factor={factor}
                  image={featuredImageUrl}
                  key={id}
                  productMatch={percentMatch}
                  productDescription={description}
                  productPrice={`$${price}`}
                  productTitle={title}
                  handle={handle}
                />
              </div>
            ))}
        </div>
      </div>

      <section className="cta-create-account">
        <TextHeading size="large" margin="both" color="light">See your full results</TextHeading>
        <a href="/signup" className="button">
          {signedIn ? 'See Full Results' : 'Create an account'}
        </a>
      </section>

      <Footer />
    </AssessmentResultStyles>
  );
};

AssessmentResultView.propTypes = propTypes;
AssessmentResultView.defaultProps = defaultProps;

export default AssessmentResultView;
