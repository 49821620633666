import { connect } from 'react-redux';
import AssessmentResultView from './assessment_result_view';
import { getResponse, getCurrentUser, getProducts } from '../../../reducer';
import { fetchResponse, fetchProducts } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const response = getResponse(state, id);
  let products = getProducts(state);
  if (response && products.size > 0) {
    products = products.sortBy((product) => {
      if (!product.get('matchScore')) {
        return 0.0;
      }
      try {
        const weights = JSON.parse(product.get('matchScore'));
        return 0.0 - Object.entries(weights).reduce((sum, [key, weight]) => (response.get(`${key}Score`) || 0.0) * weight, 0.0);
      } catch (e) {
        return 0.0;
      }
    }).slice(0, 3);
  }
  return {
    user: getCurrentUser(state),
    response,
    products,
    signedIn: getCurrentUser(state).signedIn,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id } = ownProps.match.params;
  if (localStorage.getItem('responseId') !== id) {
    localStorage.setItem('responseId', id);
  }
  return {
    fetchResponse: () => dispatch(fetchResponse(id)),
    fetchProducts: () => dispatch(fetchProducts(id)),
  };
};

const AssessmentResultContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentResultView);

export default AssessmentResultContainer;
