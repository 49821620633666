import React from 'react';
import PropTypes from 'prop-types';
import SliderStyles from './slider_styles';

const propTypes = {
  percent: PropTypes.number,
  // label: PropTypes.string,
};

const defaultProps = {
  percent: 0,
  // label: 'You',
};

const SliderView = ({ percent, _label }) => {
  const boundedPercent = Math.min(100, Math.max([0, percent]));
  let percentLabel;
  if (percent <= 33) {
    percentLabel = 'Low';
  } else if (percent <= 66) {
    percentLabel = 'Mid';
  } else {
    percentLabel = 'High';
  }
  return (
    <SliderStyles>
      <div className="sliderGraph">
        <div className="sliderGraphEl" style={{ left: `${boundedPercent}%` }}>
          {/* {label} */}
          {percentLabel}
        </div>
      </div>
    </SliderStyles>
  );
};

SliderView.propTypes = propTypes;
SliderView.defaultProps = defaultProps;

export default SliderView;
