import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/components/button';
import Icon from '../../../common/components/icon';
import { Text, TextHeading } from '../../../common/components/text';
import ProductStyles from './product_styles';
import theme from '../../../common/theme';
import { getShopifyUrl } from '../../shopify';

const propTypes = {
  factor: PropTypes.string,
  image: PropTypes.string,
  handle: PropTypes.string,
  // productMatch: PropTypes.number,
  productDescription: PropTypes.string,
  productPrice: PropTypes.string,
  productTitle: PropTypes.string,
};

const defaultProps = {
  factor: undefined,
  image: undefined,
  handle: '',
  // productMatch: undefined,
  productDescription: undefined,
  productPrice: '$0',
  productTitle: undefined,
};

const productUrl = (handle) => getShopifyUrl(`/products/${handle}`);

// TODO: get this link correct?
// const productAddUrl = (id) => `${process.env.REACT_APP_SHOPIFY_URL}/cart/add?id=${id}&quantity=1`;

const ProductView = ({ factor, image, handle, productDescription, productPrice, productTitle }) => (
  <ProductStyles>
    <header className="product-header">
      {/* <div className="product-match">
        <Text color={theme.color.text.light}>{productMatch}% Match to You</Text>
      </div> */}
      <div className="product-image" style={{ backgroundImage: `url(${image})` }} />
    </header>
    <div className="product-body">
      <TextHeading size="medium" margin="none">
        {productTitle}
      </TextHeading>
      <div className="product-meta">
        <Text size="large" color={theme.color.secondary.dark}>
          {productPrice}
        </Text>
      </div>
      <Text size="small" margin="bottom">
        <div dangerouslySetInnerHTML={{ __html: productDescription }} />
      </Text>

      <div className="product-cta">
        {/* <Button as="a" href={productAddUrl(handle)} buttonType="secondary"> */}
        {/*  Add to Cart */}
        {/* </Button> */}
        <Button as="a" href={productUrl(handle)} buttonType="secondary">
          View Item
        </Button>
      </div>

      <footer className="product-footer">
        <p>Recommended for the following:</p>
        <div className="product-factor-grid">
          <div data-active={factor && factor.get('body')} className="product-factor factor-body">
            <Icon icon="body" />
          </div>
          <div data-active={factor && factor.get('social')} className="product-factor factor-social">
            <Icon icon="social" />
          </div>
          <div data-active={factor && factor.get('mind')} className="product-factor factor-mind">
            <Icon icon="mind" />
          </div>
          <div data-active={factor && factor.get('routine')} className="product-factor factor-routine">
            <Icon icon="routine" />
          </div>
        </div>
      </footer>
    </div>
  </ProductStyles>
);

ProductView.propTypes = propTypes;
ProductView.defaultProps = defaultProps;

export default ProductView;
