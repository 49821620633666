import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Block from '../../../common/components/block';
import { TextTitle } from '../../../common/components/text';
import { FactorGraph, FactorIcon } from './factors';
import ReportsStyles from './reports_styles';
import ResponseModel from '../../models/response_model';

const propTypes = {
  user: PropTypes.shape({ signedIn: PropTypes.bool.isRequired }).isRequired,
  archives: PropTypes.shape([]).isRequired,
};

const defaultProps = {
};

// user, products,
const ReportsView = ({ archives }) => {
  if (archives && archives.size <= 0) return <Redirect to="/dashboard" />;

  return (
    <ReportsStyles>
      <Block className="wrapper-intro">
        <div className="row">
          <div className="col-md-6">
            <TextTitle margin="none">
              Your Past Results
            </TextTitle>
          </div>
        </div>
      </Block>
      <div className="wrapper">
        <div className="row">
          <div className="col-md-12">
            {
              archives ? (
                archives.map((responseData) => {
                  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                  const date = new Date(responseData.createdAt);
                  const response = new ResponseModel(responseData);
                  return (
                    <div className="result" key={response.id}>
                      <header className="result-header">
                        {(response.createdAt && date.toLocaleDateString('en-US', dateOptions)) || 'No date recorded'}
                        <Link to={`/dashboard/reports/${response.id}`}>See full results</Link>
                      </header>
                      <div className="result-factors">
                        <div className="factor" data-factor="mind">
                          <header data-factor="mind"><FactorIcon factor="mind" className="factor-icon" /></header>
                          <div className="factor-graph">
                            <FactorGraph percent={response.score('weightedMindScore')} />
                          </div>
                        </div>

                        <div className="factor" data-factor="body">
                          <header data-factor="body"><FactorIcon factor="body" className="factor-icon" /></header>
                          <div className="factor-graph">
                            <FactorGraph percent={response.score('weightedBodyScore')} />
                          </div>
                        </div>

                        <div className="factor" data-factor="social">
                          <header data-factor="social"><FactorIcon factor="social" className="factor-icon" /></header>
                          <div className="factor-graph">
                            <FactorGraph percent={response.score('weightedSocialScore')} />
                          </div>
                        </div>

                        <div className="factor" data-factor="routine">
                          <header data-factor="routine"><FactorIcon factor="routine" className="factor-icon" /></header>
                          <div className="factor-graph">
                            <FactorGraph percent={response.score('weightedRoutineScore')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>There aren&#39;t any completed responses yet!</p>
              )
            }
          </div>
        </div>
      </div>
    </ReportsStyles>
  );
};

ReportsView.propTypes = propTypes;
ReportsView.defaultProps = defaultProps;

export default ReportsView;
